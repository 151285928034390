import { Link } from "react-router-dom";
import Foto from './assets/tristesa.png'
import './App.css'

const ErrorAuth = () => {
    console.log("ERRO CARREGADO")
    return (
        <div className="containerError">
            <img className="imagemError" src="https://firebasestorage.googleapis.com/v0/b/eliteplus-f6f17.appspot.com/o/tristesa.png?alt=media&token=55aa031c-2ea9-4279-9731-fb5eb69a56be" />
            <div className="boxError">
                <h4 className="titleError">Ops, Parece que você não tem autorização para acessar essa página</h4>
                <div className="subTitleError">
                    <h6 className="subTitleErrorText">Retorne a tela de login: </h6>
                    <Link to="/">
                        <button className="ErrorButton">
                            Ir para o Login
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorAuth
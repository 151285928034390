import React, { useEffect, useState } from "react"; 
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import firebase from "../../firebase.js";
import { MdModeEdit, MdDelete, MdCancel } from 'react-icons/md';
import{IoIosSave} from 'react-icons/io';
import { auth } from "../../firebase"; // Firebase config

const db = firebase.firestore();

const DesafiosList = () => {
  const [desafios, setDesafios] = useState([]);
  const [editDesafiosId, setEditDesafiosId] = useState(null);
  const [editConteudo, setEditConteudo] = useState("");
  const [editNome, setEditNome] = useState("");
  const [editValor, setEditValor] = useState(0);
 

  useEffect(() => {
    const fetchDesafios = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Desafios"));
        const desafiosArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDesafios(desafiosArray);
      } catch (error) {
        console.error("Erro ao buscar dados do Firestore: ", error);
      }
    };

    fetchDesafios();
  }, []);

  async function handleModificacoes(id, action) {
      // Add a new document to the "Modificação" collection
     try {
      await addDoc(collection(db, "Modificações"), {
        Admin: auth.currentUser.email,
        Desafio: id,
        Action: action,
        Data: new Date().toLocaleDateString("pt-BR"), // Use a Date object for a more readable date format
      });
      
     } catch(error) {
        console.error(error)
     }
  }

  const handleUpdateDesafios = async (id) => {
    const desafiosRef = doc(db, "Desafios", id);

    try {
      handleModificacoes(id, "Atualização de Desafio")
      await updateDoc(desafiosRef, {
        Nome: editNome,
        Conteudo: editConteudo,
        Valor: editValor
      });


      setDesafios((prevDesafios) =>
        prevDesafios.map((desafio) =>
          desafio.id === id
            ? { ...desafio, Nome: editNome, Conteudo: editConteudo, Valor: editValor }
            : desafio
          
        )
      );
      setEditDesafiosId(null); // Sai do modo de edição
    } catch (error) {
      console.error("Erro ao atualizar desafio: ", error);
    }
  };

  const handleDeleteDesafios = async (id) => {
    const desafiosRef = doc(db, "Desafios", id);

    try {
      await deleteDoc(desafiosRef);


      setDesafios((prevDesafios) => prevDesafios.filter((desafio) => desafio.id !== id));
      handleModificacoes(id, "Exclusão")
    } catch (error) {
      console.error("Erro ao deletar desafio: ", error);
    }
  };

  return (
    <div className="table">
      <h4>Edição de Desafios</h4>
      <table border="1">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Conteúdo</th>
            <th>Valor</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {desafios.map((desafio) => (
            <tr key={desafio.id}>
              <td>{desafio.id}</td>
              <td>
                {editDesafiosId === desafio.id ? (
                  <input
                    type="text"
                    value={editNome}
                    onChange={(e) => setEditNome(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateDesafios(desafio.id)}
                  />
                ) : (
                  desafio.Nome
                )}
              </td>
              <td>
                {editDesafiosId === desafio.id ? (
                  <input
                    type="text"
                    value={editConteudo}
                    onChange={(e) => setEditConteudo(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateDesafios(desafio.id)}
                  />
                ) : (
                  desafio.Conteudo
                )}
              </td>
              <td>
                {editDesafiosId === desafio.id ? (
                  <input
                    type="number"
                    value={editValor}
                    onChange={(e) => setEditValor(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateDesafios(desafio.id)}
                  />
                ) : (
                  desafio.Valor
                )}
              </td>
              <td>
                {editDesafiosId === desafio.id ? (
                  <div className="actions">
                    <button onClick={() => handleUpdateDesafios(desafio.id)}>
                      <IoIosSave style={{ color: "white", height: "20px", width: "20px" }} />
                    </button>
                    <button onClick={() => setEditDesafiosId(null)}>
                      <MdCancel style={{ color: "white", height: "20px", width: "20px" }} />
                    </button>
                  </div>
                ) : (
                  <div className="actions">
                    <button className="edit"
                      onClick={() => {
                        setEditDesafiosId(desafio.id);
                        setEditNome(desafio.Nome);
                        setEditConteudo(desafio.Conteudo);
                        setEditValor(desafio.Valor);
                      }}
                    >
                      <MdModeEdit style={{ color: "white", height: "20px", width: "20px" }} />
                    </button>
                    <button className="delete" onClick={() => handleDeleteDesafios(desafio.id)}>
                      <MdDelete style={{ color: "white", height: "20px", width: "20px" }} />
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DesafiosList;

import React, { useState } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import firebase from "../../firebase.js";
import "../../App.css";
import "../../materialize.min.css";
import { auth } from "../../firebase";

const db = firebase.firestore();

const FormularioDesafios = () => {
  const [nome, setNome] = useState('');
  const [conteudo, setConteudo] = useState('');
  const [valor, setValor] = useState('');
  const [temImagem, setTemImagem] = useState(false); // Estado para a checkbox
  const [mensagem, setMensagem] = useState('');
  
  // Função para validar se o conteúdo é uma URL
  const isValidUrl = (str) => {
    const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return regex.test(str);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (nome.length > 0 && conteudo.length > 0 && valor) {

      try {
        await addDoc(collection(db, "Desafios"), {
          Nome: nome,
          Conteudo: conteudo,
          Valor: parseInt(valor),
          hasImage: temImagem, // Salvar o estado da checkbox no Firestore
          hasLink: isValidUrl(conteudo)
        });

        const q = query(collection(db, "Desafios"), where("Nome", "==", nome), where("Conteudo", "==", conteudo), where("Valor", "==", parseInt(valor)));
        const querySnapshot = await getDocs(q);
        let NomeDesafio = "";

        querySnapshot.forEach((doc) => {
          NomeDesafio = doc.id;
        });

        await addDoc(collection(db, "Modificações"), {
          Admin: auth.currentUser ? auth.currentUser.email : "Unknown Admin",
          Desafio: NomeDesafio,
          Action: "Criação Desafio",
          Data: new Date().toLocaleDateString("pt-BR"),
        });

        submitNotification(nome);
        setMensagem("Desafio criado com sucesso!");
        setNome("");
        setConteudo("");
        setValor("");
        setTemImagem(false); // Resetar a checkbox
      } catch (error) {
        setMensagem("Erro ao enviar dados: " + error.message);
      }
    } else {
      setMensagem("Dados Incompletos! Por favor preencha todos os campos.");
    }
  };

  const submitNotification = (Subtitulo) => {
    addDoc(collection(db, "Notificações"), {
      Titulo: "Novo Desafio Disponível!",
      Subtitulo: "O Desafio " + Subtitulo + " está disponível, Complete-o e Venha Ser Elite!",
      Data: new Date(),
      ReadBy: []
    });
  };

  return (
    <>
      <div className="container">
        <h4>Adicionar Desafio</h4>
        <form className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="input-field col s6">
              <input
                style={{ width: "426px" }}
                id="nome"
                type="text"
                className="validate"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <label className="active" htmlFor="nome">Nome do Desafio</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s6">
              <input
                style={{ width: "205px" }}
                id="conteudo"
                type="text"
                className="validate"
                value={conteudo}
                onChange={(e) => setConteudo(e.target.value)}
              />
              <label className="active" htmlFor="conteudo">Conteúdo</label>
            </div>

            <div className="input-field col s6">
              <input
                style={{ width: "205px" }}
                id="valor"
                type="number"
                className="validate"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
              <label className="active" htmlFor="valor">Valor</label>
            </div>
          </div>

          <div className="row">
            <div className="col s6">
              <label>
                <input
                  type="checkbox"
                  className="filled-in offset-s6 right"
                  checked={temImagem}
                  onChange={(e) => setTemImagem(e.target.checked)} // Atualiza o estado com o valor da checkbox
                />
                <span>Solicitar Imagem</span>
              </label>
            </div>
          </div>

          <input className="botao" type="submit" value="Cadastrar" />
        </form>
        {mensagem && <p>{mensagem}</p>}
      </div>
    </>
  );
};

export default FormularioDesafios;

import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCBkkOeK7U4nxFOBp7grpf6-JeadQm9AtM",
    authDomain: "eliteplus-f6f17.firebaseapp.com",
    projectId: "eliteplus-f6f17",
    storageBucket: "eliteplus-f6f17.appspot.com",
    messagingSenderId: "405793865776",
    appId: "1:405793865776:web:0b0f73a830a1ee0b63adf5"
  };

if(!firebase.apps.length){ //deixa uma rota
  console.log(`Conectando...  Status:${firebase.apps.length}`);
  firebase.initializeApp(firebaseConfig);
  console.log(`Conectado  Status:${firebase.apps.length}`);
}

export const storage = getStorage(firebase.initializeApp(firebaseConfig));
const auth = getAuth(firebase.initializeApp(firebaseConfig));

export default firebase;
export { auth };
import React, { useEffect, useState, useRef } from "react";
import { collection, onSnapshot, query, orderBy, doc, updateDoc } from "firebase/firestore";
import firebase from "../../firebase.js";

const db = firebase.firestore();

const ComprasList = () => {
  const [compras, setCompras] = useState([]);
  const [alunos, setAlunos] = useState({});
  const [items, setItems] = useState({});
  const [loadingAlunos, setLoadingAlunos] = useState(true);
  const [loadingItems, setLoadingItems] = useState(true);
  const [filter, setFilter] = useState("todos"); // Estado para controle do filtro
  const [buttonText, setButtonText] = useState("Todos"); // Texto do botão de filtro
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, "Compras"), orderBy('Data', 'desc')), (querySnapshot) => {
      const compraArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        recebido: doc.data().recebido || false,
      }));
      setCompras(compraArray);
      fetchAlunos(compraArray);
      fetchItems(compraArray);
    }, (error) => {
      console.error("Erro ao achar compras: ", error);
    });

    return () => unsubscribe();
  }, []);

  const fetchAlunos = async (compras) => {
    const uniqueAlunos = [...new Set(compras.map(compra => compra.Aluno))];
    const alunosData = {};

    setLoadingAlunos(true);
    for (const alunoId of uniqueAlunos) {
      try {
        const querySnapshot = await db.collection('Usuários').doc(alunoId).get();
        if (querySnapshot.exists) {
          alunosData[alunoId] = querySnapshot.data().Nome;
        }
      } catch (error) {
        console.error(`Erro ao buscar aluno ${alunoId}:`, error);
      }
    }
    setAlunos(alunosData);
    setLoadingAlunos(false);
  };

  const fetchItems = async (compras) => {
    const uniqueItems = [...new Set(compras.map(compra => compra.Item))];
    const itemData = {};

    setLoadingItems(true);
    for (const itemId of uniqueItems) {
      try {
        const querySnapshot = await db.collection('Itens').doc(itemId).get();
        if (querySnapshot.exists) {
          itemData[itemId] = querySnapshot.data().Nome;
        }
      } catch (error) {
        console.error(`Erro ao buscar item ${itemId}:`, error);
      }
    }
    setItems(itemData);
    setLoadingItems(false);
  };

  const toggleRecebido = async (id) => {
    const compra = compras.find(compra => compra.id === id);
    const newRecebidoState = !compra.recebido;

    await updateDoc(doc(db, "Compras", id), {
      recebido: newRecebidoState,
    });

    setCompras(prevCompras =>
      prevCompras.map(compra =>
        compra.id === id ? { ...compra, recebido: newRecebidoState } : compra
      )
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleFilter = (action) => {
    setFilter(action);
    setButtonText(action === "todos" ? "Todos" : action === "recebidos" ? "Recebidos" : "Não Recebidos");
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filtrando as compras com base no estado do filtro
  const comprasFiltradas = compras.filter(compra => {
    if (filter === "recebidos") return compra.recebido;
    if (filter === "naoRecebidos") return !compra.recebido;
    return true; // Para "todos"
  });

  return (
    <div className="table">
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <h4>Lista de Compras</h4>
      <div className="dropdown" style={{ display: "flex", justifyContent: "flex-end", position: "relative" }} ref={dropdownRef}>
        <button onClick={toggleDropdown} className="dropdown-toggle">
          {buttonText}
        </button>
         {isOpen && (
          <ul
            className="dropdown-menu"
            style={{
              position: "absolute",
              top: "100%",
              right: "0",
              backgroundColor: "white",
              listStyle: "none",
              padding: "10px",
              margin: "0",
              border: "1px solid #ccc",
              width: "200px",
            }}
          >
            <li onClick={() => handleFilter("todos")}>Todos</li>
            <li onClick={() => handleFilter("recebidos")}>Recebidos</li>
            <li onClick={() => handleFilter("naoRecebidos")}>Não Recebidos</li>
          </ul>
        )}
      </div>
  
       
      </div>

      {loadingAlunos || loadingItems ? (
        <p>Carregando dados...</p>
      ) : (
        <ul>
          {comprasFiltradas.map((compra) => (
            <li
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                borderBottom: "1px solid #ccc",
                paddingBottom: "10px",
              }}
              key={compra.id}
            >
              O aluno {alunos[compra.Aluno] || "Desconhecido"} comprou {items[compra.Item] || "item desconhecido"} na data {compra.Data}
              <span style={{ marginLeft: "10px", fontWeight: "800" }}>
                {compra.recebido ? "-   Recebido" : "-   Não Recebido"}
              </span>
              <button className="marcar-recebido" onClick={() => toggleRecebido(compra.id)} style={{ marginLeft: "auto" }}>
                {compra.recebido ? "Marcar como não entregue" : "Marcar como Entregue"}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default ComprasList;

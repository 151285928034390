import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TabExample from './user';  // Exemplo de uma página
import Desafios from './desafios'; // Outra página
import Premios from './premios';
import Logs from './logs';
import Login from './login';
import ErrorAuth from './ErrorAuth';

function App() {
  console.log("App component rendered");
  return (
    <Router>
      <Routes>
        <Route path="/user" element={<TabExample />} />
        <Route path="/desafios" element={<Desafios />} />
        <Route path="/premios" element={<Premios />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/" element={<Login />} />
        <Route path="/ErrorAuth" element={<ErrorAuth/>}/>
      </Routes>
    </Router>
  );
}


export default App;


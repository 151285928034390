import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import firebase from "../../firebase.js";
import { MdModeEdit } from 'react-icons/md'; // Importa um ícone específico
import { MdDelete } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { auth } from "../../firebase"; // Firebase config

const db = firebase.firestore();

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editLogin, setEditLogin] = useState("");
  const [editNome, setEditNome] = useState("");
  const [editSenha, setEditSenha] = useState(null);
  const [editMoedas, setEditMoedas] = useState(0);
  const [editPontos, setEditPontos] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // Adiciona o estado para o termo de busca

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Usuários"));
        const usersArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersArray);
      } catch (error) {
        console.error("Erro ao buscar dados do Firestore: ", error);
      }
    };

    fetchUsers();
  }, []);

  async function handleModificacoes(id, action) {
    // Add a new document to the "Modificação" collection
    try {
      await addDoc(collection(db, "Modificações"), {
        Admin: auth.currentUser.email,
        Aluno: id,
        Action: action,
        Data: new Date().toLocaleDateString("pt-BR"), // Use a Date object for a more readable date format
      });

    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdateUser = async (id) => {
    const userRef = doc(db, "Usuários", id);

    try {
      await updateDoc(userRef, {
        Nome: editNome,
        Login: editLogin,
        Senha: parseInt(editSenha),
        Moedas: parseInt(editMoedas),
        Pontos: parseInt(editPontos),
      });

      // Atualiza a lista local
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === id
            ? { ...user, Nome: editNome, Login: editLogin, Senha: editSenha, Moedas: editMoedas, Pontos: editPontos }
            : user
        )
      );
      setEditUserId(null); // Sai do modo de edição
    } catch (error) {
      console.error("Erro ao atualizar usuário: ", error);
    }
  };

  // Função para deletar um usuário
  const handleDeleteUser = async (id) => {
    const userRef = doc(db, "Usuários", id);

    try {
      await deleteDoc(userRef);

      // Remove o usuário da lista local
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
      handleModificacoes(id, "Exclusão");
    } catch (error) {
      console.error("Erro ao deletar usuário: ", error);
    }
  };

  // Função para filtrar usuários com base no searchTerm
  const filteredUsers = users.filter(user => {
    return (
      user.Nome.toLowerCase().includes(searchTerm.toLowerCase()) || 
      user.Login.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className="table">
      <h4>Edição de usuários</h4>
      
      {/* Campo de pesquisa */}
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}> 
        <input
          type="text"
          placeholder="Pesquisar usuário..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o termo de pesquisa
          style={{ marginBottom: "20px", padding: "10px", marginRight: "10px"}}
        />
      </div>
       

      <table border="1">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Login</th>
            <th>Senha</th>
            <th>Moedas</th>
            <th>Pontos</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {/* Mapeia a lista de usuários filtrados */}
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editNome}
                    onChange={(e) => setEditNome(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateUser(user.id)}
                  />
                ) : (
                  user.Nome
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editLogin}
                    onChange={(e) => setEditLogin(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateUser(user.id)}
                  />
                ) : (
                  user.Login
                )
                }
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="text"
                    value={editSenha}
                    onChange={(e) => setEditSenha(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateUser(user.id)}
                  />
                ) : (
                  user.Senha
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="number"
                    value={editMoedas}
                    onChange={(e) => setEditMoedas(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateUser(user.id)}
                  />
                ) : (
                  user.Moedas
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <input
                    type="number"
                    value={editPontos}
                    onChange={(e) => setEditPontos(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdateUser(user.id)}
                  />
                ) : (
                  user.Pontos
                )}
              </td>
              <td>
                {editUserId === user.id ? (
                  <>
                    {/* Botão para salvar a edição */}
                    <div className="actions">
                      <button onClick={() => handleUpdateUser(user.id)}>
                        <IoIosSave style={{ color: "white", height: "20px", width: "20px" }} />
                      </button>
                      <button onClick={() => setEditUserId(null)}>
                        <MdCancel style={{ color: "white", height: "20px", width: "20px" }} />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Botão para entrar no modo de edição */}
                    <div className="actions">
                      <button className="edit"
                        onClick={() => {
                          setEditUserId(user.id);
                          setEditNome(user.Nome);
                          setEditLogin(user.Login);
                          setEditSenha(user.Senha);
                          setEditMoedas(user.Moedas);
                          setEditPontos(user.Pontos);
                        }}
                      >
                        <MdModeEdit style={{ color: "white", height: "20px", width: "20px" }} />
                      </button>
                      {/* Botão para deletar o usuário */}
                      <button className="delete" onClick={() => handleDeleteUser(user.id)}>
                        <MdDelete style={{ color: "white", height: "20px", width: "20px" }} />
                      </button>
                    </div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;

import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./logo.png";
import Formulario from "./components/Form";
import UserList from "./components/ListUsers";
import SolicitacoesList from "./components/Solicitacoes";
import { Link } from "react-router-dom";
import { auth } from "./firebase"; // Firebase config
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import firebase from "./firebase.js"; // Import Firebase configuration
import { collection, addDoc, query, where, getDocs } from "firebase/firestore"; 

const db = firebase.firestore();

function TabExample() {
  const navigate = useNavigate();
  // Estado para armazenar a aba ativa (pode ser "Home", "Listar", "Contato", etc.)
  const [activeTab, setActiveTab] = useState("Cadastrar");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(()=>{
    document.title= "Users - " + document.title
  }, [])

  useEffect(() => {
    // Verifica a autenticação do usuário ao montar o componente
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Se o usuário está autenticado, atualiza o estado
        setIsAuthenticated(true);
      } else {
        // Se não estiver autenticado, redireciona para a página de erro
        navigate("/ErrorAuth");
      }
    });

    // Limpa o listener ao desmontar o componente
    return () => unsubscribe();
  }, [navigate]);

  // Função para alterar a aba ativa
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSubmit = async () => {
    console.log('Começou')
    


    console.log("Fim")
  }

  return (

    <div className="body">
        <div className="cabecario">
            <div className="logo" >
              <img src={logo} alt="logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70px", width: "320px" }} />
            </div>
            <div className="nav">
               <button className="ativado" >Usuários</button>
              <Link to="/desafios"><button>Desafios</button></Link>
              <Link to="/premios"><button>Prêmios</button></Link>
              <Link to="/logs"><button >Logs</button></Link>
            </div>
        </div> 
      {/* Cabeçalho das Abas */}
      <div className="tab" style={{display : "flex", justifyContent: "space-around", alignItems: "center"}}>
        <button
          style={{width: "300px"}}
          className={activeTab === "Cadastrar" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Cadastrar")}
        >
          Cadastrar
        </button>
        <button
          style={{width: "600px"}}
          className={activeTab === "Listar" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Listar")}
        >
          Listar
        </button>
        <button
          sty
          className={activeTab === "Solicitações" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Solicitações")}
        >
          Solicitações
        </button>
      </div>

      {/* Conteúdo das Abas */}
      <div className="tab-content">
        {activeTab === "Cadastrar" && (
           <div className="tabcontent">
              <Formulario/>
           </div> 
        )}
        {activeTab === "Listar" && (
           <div className="tabcontent">
              <UserList/>
          </div>
        )}
        {activeTab === "Solicitações" && (
          <div className="tabcontent">
            <SolicitacoesList/>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabExample;

import React, { useState } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore"; // Import the required Firestore functions
import firebase from "../../firebase.js"; // Import Firebase configuration
import { auth } from "../../firebase"; // Firebase config
import "../../App.css";
import "../../materialize.min.css";

const db = firebase.firestore();

const Formulario = () => {
  
  const [nome, setNome] = useState("");
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState(null);
  const [mensagem, setMensagem] = useState("");
  const idAdmin = auth.currentUser ? auth.currentUser.email : false

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (nome.length > 0 && login.length > 0 && senha.length > 0) {
      try {
        // Add a new document to the "Usuários" collection
        await addDoc(collection(db, "Usuários"), {
          Nome: nome,
          Login: login,
          Senha: parseInt(senha),
          Moedas: 0,
          Pontos: 0,
          DesafiosConcluidos: [],
        });

        // Reset form fields
        setNome("");
        setLogin("");
        setSenha("");
        setMensagem("Usuário criado com sucesso!");

        // Query the user to get the created document's data
        const q = query(collection(db, "Usuários"), where("Login", "==", login), where("Senha", "==", senha), where("Nome", "==", nome));
        const querySnapshot = await getDocs(q);
        let idAluno = "";
        
        querySnapshot.forEach((doc) => {
          idAluno = doc.id;
        });

        // Add a new document to the "Modificação" collection
        await addDoc(collection(db, "Modificações"), {
          Admin: idAdmin ? idAdmin : "Unkonwn",
          Aluno: idAluno,
          Action: "Criação Usuário",
          Data: new Date().toLocaleDateString("pt-BR"), // Use a Date object for a more readable date format
        });
      } catch (error) {
        setMensagem("Erro ao enviar dados: " + error.message);
      }
    } else {
        setMensagem("Dados Incompletos! Por favor preencha todos os campos");
    }
  };


  return (
    <div className="container">
      <h4>Cadastro de usuários</h4>
      <form className="form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="input-field col s6">
            <input
              style={{ width: "426px" }}
              id="nome"
              type="text"
              className="validate"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label className="active" htmlFor="nome">
              Nome Completo
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s6">
            <input
              style={{ width: "205px" }}
              id="login"
              type="text"
              className="validate"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <label className="active" htmlFor="login">
              Login
            </label>
          </div>

          <div className="input-field col s6">
            <input
              style={{ width: "205px" }}
              id="senha"
              type="number"
              className="validate"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
            <label className="active" htmlFor="senha">
              Senha
            </label>
          </div>
        </div>
        <input className="botao" type="submit" value="Cadastrar" />
      </form>
      {mensagem && <p>{mensagem}</p>}
    </div>
  );
};

export default Formulario;
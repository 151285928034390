import React, { useEffect, useState, useRef } from "react";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import firebase from "../../firebase.js";

const db = firebase.firestore();

const ModificacoesList = () => {
  const [modificacoes, setModificacoes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [filter, setFilter] = useState(null);
  const [buttonText, setButtonText] = useState("Todos");

  // Função para buscar o nome baseado no ID e na tabela
  const idpornome = async (id, tabela) => {
    const refObject = doc(db, tabela, id);
    const docSnap = await getDoc(refObject);
    if (docSnap.exists()) {
      return docSnap.data().Nome + " "; // Retorna o nome associado ao ID
    }
    return ''; // Retorna vazio caso o documento não exista
  };

  // Função para alternar o dropdown de filtro
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Função para fechar o dropdown
  const closeDropdown = () => {
    setIsOpen(false);
  };

  // Função para selecionar o filtro e atualizar o texto do botão
  function handleFilter(action) {
    setFilter(action);
    setButtonText(action || "Todos");
    closeDropdown();
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Efeito para escutar as modificações e buscar os nomes associados
  useEffect(() => {
    const fetchModificacoes = async () => {
      const unsubscribe = onSnapshot(
        collection(db, "Modificações"),
        async (querySnapshot) => {
          const modificacaoArray = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Para cada modificação, buscamos os nomes dos Aluno, Item, e Desafio
          const modificacoesComNomes = await Promise.all(
            modificacaoArray.map(async (modificacao) => {
              const alunoNome = modificacao.Aluno ? await idpornome(modificacao.Aluno, "Usuários") : '';
              const itemNome = modificacao.Item ? await idpornome(modificacao.Item, "Itens") : '';
              const desafioNome = modificacao.Desafio ? await idpornome(modificacao.Desafio, "Desafios") : '';
              
              return {
                ...modificacao,
                alunoNome,
                itemNome,
                desafioNome,
              };
            })
          );

          setModificacoes(modificacoesComNomes);
        },
        (error) => {
          console.error("Erro ao escutar as modificações: ", error);
        }
      );

      return () => unsubscribe();
    };

    fetchModificacoes();
  }, []);

  return (
    <div className="table">
      <div className="table-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
        <h4>Lista de Modificações</h4>
        <div className="dropdown" style={{ position: "relative" }} ref={dropdownRef}>
          <button onClick={toggleDropdown} className="dropdown-toggle">
            {buttonText}
          </button>

          {isOpen && (
            <ul
              className="dropdown-menu"
              style={{
                position: "absolute",
                top: "100%",
                right: "0",
                backgroundColor: "white",
                listStyle: "none",
                padding: "10px",
                margin: "0",
                border: "1px solid #ccc",
                width: "200px",
              }}
            >
              <li onClick={() => handleFilter(null)}>Todos</li>
              <li onClick={() => handleFilter("Criação Usuário")}>Criação Usuários</li>
              <li onClick={() => handleFilter("Criação Desafio")}>Criação Desafios</li>
              <li onClick={() => handleFilter("Criação Item")}>Criação Prêmios</li>
              <li onClick={() => handleFilter("Exclusão")}>Exclusão</li>
            </ul>
          )}
        </div>
      </div>
      <ul>
        {modificacoes.map((modificacao) => {
          if (!filter || modificacao.Action === filter) {
            return (
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "10px",
                }}
                key={modificacao.id}
              >
                {modificacao.Action}: {modificacao.alunoNome} {modificacao.itemNome} {modificacao.desafioNome} pelo Admin {modificacao.Admin}, na data {modificacao.Data}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default ModificacoesList;

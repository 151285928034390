import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import firebase from "../../firebase.js";
import { MdModeEdit } from 'react-icons/md'; // Importa um ícone específico
import { MdDelete } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { auth } from "../../firebase"; // Firebase config


const db = firebase.firestore();

const PremiosList = () => {
  const [premios, setPremios] = useState([]);
  const [editPremiosId, setEditPremiosId] = useState(null);
  const [editImagem, setEditImagem] = useState("");
  const [editNome, setEditNome] = useState("");
  const [editValor, setEditValor] = useState(0);
  const [editEstoque, setEditEstoque] = useState(0)

  useEffect(() => {
    const fetchPremios = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Itens"));
        const PremiosArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPremios(PremiosArray);
      } catch (error) {
        console.error("Erro ao buscar dados do Firestore: ", error);
      }
    };

    fetchPremios();
  }, []);

  async function handleModificacoes(id, action) {
    // Add a new document to the "Modificação" collection
   try {
    await addDoc(collection(db, "Modificações"), {
      Admin: auth.currentUser.email,
      Item: id,
      Action: action,
      Data: new Date().toLocaleDateString("pt-BR"), // Use a Date object for a more readable date format
    });
    
   } catch(error) {
      console.error(error)
   }
}

  const handleUpdatePremios = async (id) => {
    const PremiosRef = doc(db, "Itens", id);

    try {
      await updateDoc(PremiosRef, {
        Nome: editNome,
        Imagem: editImagem,
        Valor: editValor,
        Estoque: parseInt(editEstoque)
      });

      // Atualiza a lista local
      setPremios((prevPremios) =>
        prevPremios.map((premios) =>
          premios.id === id
            ? { ...premios, Nome: editNome, Imagem: editImagem, Valor: editValor, Estoque: editEstoque }
            : premios
        )
      );
      setEditPremiosId(null); // Sai do modo de edição
    } catch (error) {
      console.error("Erro ao atualizar usuário: ", error);
    }
  };

  // Função para deletar um usuário
  const handleDeletePremios = async (id) => {
    const PremiosRef = doc(db, "Itens", id);

    try {
      await deleteDoc(PremiosRef);

      // Remove o usuário da lista local
      setPremios((prevPremios) => prevPremios.filter((premios) => premios.id !== id));
      handleModificacoes(id, "Exclusão")
    } catch (error) {
      console.error("Erro ao deletar usuário: ", error);
    }
  };

  return (
    <div className="table">
      <h4>Edição de Premios</h4>
      <table border="1">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Imagem</th>
            <th>Valor</th>
            <th>Estoque</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {premios.map((premios) => (
            <tr key={premios.id}>
              <td>{premios.id}</td>
              <td>
                {editPremiosId === premios.id ? (
                  <input
                    type="text"
                    value={editNome}
                    onChange={(e) => setEditNome(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdatePremios(premios.id)}
                  />
                ) : (
                  premios.Nome
                )}
              </td>
              <td>
                {
                  premios.Imagem
                }
              </td>
              <td>
                {editPremiosId === premios.id ? (
                  <input
                    type="text"
                    value={editValor}
                    onChange={(e) => setEditValor(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdatePremios(premios.id)}
                  />
                ) : (
                  premios.Valor
                )}
              </td>
              <td>
                {editPremiosId === premios.id ? (
                  <input
                    type="text"
                    value={editEstoque}
                    onChange={(e) => setEditEstoque(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleUpdatePremios(premios.id)}
                  />
                ) : (
                  premios.Estoque
                )}
              </td>
            
              <td>
                {editPremiosId === premios.id ? (
                  <>
                    {/* Botão para salvar a edição */}
                    <div className="actions">
                    <button onClick={() => handleUpdatePremios(premios.id)}>
                      <IoIosSave style={{color: "white", height: "20px", width: "20px"}} />
                    </button>
                    <button onClick={() => setEditPremiosId(null)}>
                      <MdCancel style={{color: "white", height: "20px", width: "20px"}}/>
                    </button>
                  </div>
                  </>
                ) : (
                  <>
                    {/* Botão para entrar no modo de edição */}
                    <div className="actions">
                    <button className="edit"
                      onClick={() => {
                        setEditPremiosId(premios.id);
                        setEditNome(premios.Nome);
                        setEditImagem(premios.Imagem);
                        setEditValor(premios.Valor);
                        setEditEstoque(premios.Estoque);
                      }}
                    >
                      <MdModeEdit style={{color: "white", height: "20px", width: "20px"}}/>
                    </button>
                    {/* Botão para deletar o usuário */}
                    <button className="delete" onClick={() => handleDeletePremios(premios.id)}>
                      <MdDelete style={{color: "white", height: "20px", width: "20px"}}/>
                    </button>
                    </div> 
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PremiosList;

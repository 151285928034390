import React, { useState } from "react";
import { MdDriveFolderUpload } from "react-icons/md";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore"; // Import the required Firestore functions
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase Storage
import firebase from "../../firebase.js"; // Importar a configuração do Firebase
import { storage } from '../../firebase.js'
import { auth } from "../../firebase"; // Firebase config
import "../../App.css";
import "../../materialize.min.css";


// Inicializa Firestore e Storage
const db = firebase.firestore();

const FormularioPremios = () => {
  const [nome, setNome] = useState("");
  const [imagem, setImagem] = useState(null); // Aqui, armazena o arquivo da imagem
  const [valor, setValor] = useState();
  const [estoque, setEstoque] = useState(null);
  const [mensagem, setMensagem] = useState("");
  const [progresso, setProgresso] = useState(0); // Para acompanhar o progresso do upload
  const [nomeArquivo, setNomeArquivo] = useState(""); // State to store the filename

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (nome && valor && imagem){
        
        if (!imagem) {
          setMensagem("Por favor, selecione uma imagem.");
          return;
        }

        const storageRef = ref(storage, `imagens/${imagem.name}`); // Referência para a imagem no Firebase Storage
        const uploadTask = uploadBytesResumable(storageRef, imagem); // Faz o upload da imagem

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progressoUpload = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgresso(progressoUpload); // Atualiza o progresso
          },
          (error) => {
            setMensagem("Erro ao enviar a imagem: " + error.message);
          },
          () => {
            // Quando o upload for completo, pega a URL da imagem e salva no Firestore
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              addDoc(collection(db, "Itens"), {
                Nome: nome,
                Imagem: downloadURL, // Salva a URL da imagem no Firestore
                Valor: parseInt(valor),
                Estoque: parseInt(valor),
              })
                .then(async () => {
                  submitNotification(nome);
                  setMensagem("Prêmio cadastrado com sucesso!");
                  setNome("");
                  setImagem(null);
                  setValor();
                  setEstoque();
                  setProgresso(0);
                  setNomeArquivo('')

                  const q = query(collection(db, "Itens"), where("Imagem", "==", downloadURL), where("Valor", "==", parseInt(valor)), where("Nome", "==", nome));
                  const querySnapshot = await getDocs(q);
                  let idItem = "";
                  
                  querySnapshot.forEach((doc) => {
                    idItem = doc.id;
                  });

                  // Add a new document to the "Modificação" collection
                  await addDoc(collection(db, "Modificações"), {
                    Admin: auth.currentUser ? auth.currentUser.email : "Unknown Admin",
                    Item: idItem,
                    Action: "Criação Item",
                    Data: new Date().toLocaleDateString("pt-BR"), // Use a Date object for a more readable date format
                  });
                })
                .catch((error) => {
                  setMensagem("Erro ao salvar no Firestore: " + error.message);
                });
            });
          }
        );
      }else{
        setMensagem("Dados Incompletos! Por favor insira todos os dados!")
      }
    }

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImagem(e.target.files[0]);
      setNomeArquivo(e.target.files[0].name); // Update the filename state
    }
  };

  const submitNotification = ( Subtitulo) => {
    addDoc(collection(db, "Notificações"), {
      Titulo: "Novo Prêmio Disponível!",
      Subtitulo: "O Prêmio " + Subtitulo + " está disponível!",
      Data: new Date(),
      ReadBy: []
    })
  }

  return (
    <>
      <div className="container">
        <h4>Cadastre seu Prêmio</h4>
        <form className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="input-field col s6">
              <input
                style={{ width: "426px" }}
                id="nome"
                type="text"
                className="validate"
                value={nome}  
                onChange={(e) => setNome(e.target.value)}
              />
              <label className="active" htmlFor="nome">Nome do Prêmio</label>
            </div>
          </div>

          <div className="row">
            <div className="file-field input-field col s6">
              <div className="btn">
                <span> <MdDriveFolderUpload style={{ height: "20px", width: "20px" }}/></span>
                <input type="file"
                   style={{ width: "205px" }}
                   id="imagem"
                   className="teste"
                   onChange={handleImageChange} // Chama a função para capturar a imagem
                />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate" type="text" readOnly={true} value={nomeArquivo} style={{fontSize: 12}}/> 
              </div>
            </div>

            <div className="input-field col s6">
              <input
                style={{ width: "205px" }}
                id="valor"
                type="number"
                className="validate"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
              <label className="active" htmlFor="valor">Valor</label>
            </div>
          </div>
          <div className="linha90  col s12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div className="input-field col s6">
                <input
                  style={{ width: "205px" }}
                  id="Estoque"
                  type="number"
                  className="validate"
                  value={estoque}
                  onChange={(e) => setEstoque(e.target.value)}
                  />
                <label className="active" htmlFor="Estoque">Estoque</label>
            </div>
          </div>
            

          <input className="botao" type="submit" value="Cadastrar" />
        </form>
        {progresso > 0 && <p>Progresso: {progresso}%</p>} {/* Exibe o progresso */}
        {mensagem && <p>{mensagem}</p>}
      </div>
    </>
  );
};

export default FormularioPremios;

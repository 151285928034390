import React, { useEffect, useState, useRef } from "react";
import { arrayUnion, collection, updateDoc, doc, onSnapshot } from "firebase/firestore";
import firebase from "../../firebase.js";
import { FaCheck, FaPaperclip } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { GiCancel } from "react-icons/gi";

const db = firebase.firestore();

const SolicitacoesList = () => {
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [filter, setFilter] = useState("naoLidas"); // Estado para controle do filtro
  const [buttonText, setButtonText] = useState("Não Lidas"); // Texto do botão de filtro
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [imageLink, setImageLink] = useState("")


  useEffect(() => {
    // Real-time listener for the Solicitações collection
    const unsubscribe = onSnapshot(collection(db, "Solicitações"), (querySnapshot) => {
      const solicitacaoArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSolicitacoes(solicitacaoArray);
    }, (error) => {
      console.error("Erro ao escutar as solicitações: ", error);
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, []);

  async function handleSolicitacoes(id_Aluno, id_Desafio, id_solicitacao, valor, concluido) {
    const refUser = doc(db, "Usuários", id_Aluno);
    if (concluido) {
      await updateDoc(refUser, {
        DesafiosConcluidos: arrayUnion(id_Desafio),
        Moedas: firebase.firestore.FieldValue.increment(valor),
        Pontos: firebase.firestore.FieldValue.increment(100),
      });
    }

    const solicitacaoRef = doc(db, "Solicitações", id_solicitacao);

    try {
      await updateDoc(solicitacaoRef, {
        Lido: true,
        Estado: concluido,
      });
    } catch (error) {
      console.error("Erro ao atualizar solicitação: ", error);
    }
  }

  const OpenImageOverlay = (link) => {
    setImageLink(link)
    setIsImageOpen(true)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleFilter = (action) => {
    setFilter(action);
    setButtonText(action === "todas" ? "Todas" : action === "naoLidas" ? "Não Lidas" : "Lidas");
    closeDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filtrando as solicitações com base no estado do filtro
  const solicitacoesFiltradas = filter === "naoLidas"
    ? solicitacoes.filter((solicitacao) => !solicitacao.Lido)
    : filter === "todas" ? solicitacoes : solicitacoes.filter((solicitacao) => solicitacao.Lido);

  // Ordenando as solicitações, com as não lidas no topo
  const solicitacoesOrdenadas = solicitacoesFiltradas.sort((a, b) => {
    if (a.Lido === b.Lido) return 0; // Mantém a ordem se ambos são iguais
    return a.Lido ? 1 : -1; // Coloca as não lidas primeiro
  });

  return (
    <div className="table">
      <div  className="table-header" style={{display: "flex", justifyContent: "space-between", alignItems: "center", FlexDirection: "row"}}>
      <h4>Lista de Solicitações</h4>
      <div className="dropdown" style={{ display: "flex", justifyContent: "flex-end", position: "relative" }} ref={dropdownRef}>
        <button onClick={toggleDropdown} className="dropdown-toggle">
          {buttonText}
        </button>

        {isOpen && (
          <ul
            className="dropdown-menu"
            style={{
              position: "absolute",
              top: "100%",
              right: "0",
              backgroundColor: "white",
              listStyle: "none",
              padding: "10px",
              margin: "0",
              border: "1px solid #ccc",
              width: "200px",
            }}
          
          >
            <li onClick={() => handleFilter("naoLidas")}>Não Lidas</li>
            <li onClick={() => handleFilter("Lidas")}>Lidas</li>
            <li onClick={() => handleFilter("todas")}>Todas</li>
          </ul>
        )}
      </div>
      </div>

      {solicitacoesOrdenadas.length === 0 ? (
        <p>Nenhuma solicitação disponível.</p>
      ) : (
        <ul>
          {solicitacoesOrdenadas.map((solicitacao) => (
            <>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                borderBottom: "1px solid #ccc",
                paddingBottom: "10px",
              }}
              key={solicitacao.id}
            >
              <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 50
              }}>
                <span>O aluno {solicitacao.Aluno}, de login {solicitacao.LoginAluno}, solicitou a recompensa do desafio {solicitacao.Desafio} no dia {solicitacao.Data}</span>
                {solicitacao.Imagem && (
                  <button className="clip" onClick={() => OpenImageOverlay(solicitacao.Imagem)}> <FaPaperclip style={{ color: "white", height: "20px", width: "20px", fontWeight: "bold"}}/> </button>
                )}
              </div>
              <div className="actions" style={{ marginLeft: "auto" }}>
                {solicitacao.Lido ? null : (
                  <>
                    <button className="check" onClick={() => handleSolicitacoes(solicitacao.id_Aluno, solicitacao.id_Desafio, solicitacao.id, solicitacao.Valor, true)}>
                      <FaCheck style={{ color: "white", height: "20px", width: "20px" }} />
                    </button>
                    <button className="cancel" onClick={() => handleSolicitacoes(solicitacao.id_Aluno, solicitacao.id_Desafio, solicitacao.id, solicitacao.Valor, false)}>
                      <TiCancel style={{ color: "white", height: "20px", width: "20px", fontWeight: "bold"}} />
                    </button>
                  </>
                )}
              </div>
            </li>
            {isImageOpen && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 90,
                  left: '50%',
                  top:'50%',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end'
                }}
                className="imageOverlay"
              >
                 <div className="Xbutton">
                  <GiCancel onClick={() => setIsImageOpen(false)} color="Black" size={40} />
                 </div>
                <img src={imageLink} style={{
                  width: 500,
                  height: 500,
                  resize: 'contain'
                }}
              />
              </div>
            )}
            </>
          ))}
        </ul>
      )}    
    </div>
  );
};

export default SolicitacoesList;

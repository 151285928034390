import React, { useState, useEffect} from "react";
import "./App.css";
import logo from "./logo.png";
import  FormularioPremios from "./components/FormPremios";
import  PremiosList from "./components/ListPremios";
import  ComprasList from "./components/Compras";
import { Link } from "react-router-dom";
import { auth } from "./firebase"; // Importa a configuração do Firebase
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";


function Premios() {

  const navigate = useNavigate();
    // Estado para armazenar a aba ativa (pode ser "Home", "Sobre", "Contato", etc.)
  const [activeTab, setActiveTab] = useState("Cadastrar");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Verifica a autenticação do usuário ao montar o componente
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Se o usuário está autenticado, atualiza o estado
        setIsAuthenticated(true);
      } else {
        // Se não estiver autenticado, redireciona para a página de erro
        navigate("/ErrorAuth");
      }
    });

    // Limpa o listener ao desmontar o componente
    return () => unsubscribe();
  }, [navigate]);

  // Função para alterar a aba ativa
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
    return (
        <div className="body">
        <div className="cabecario">
            <div className="logo">
              <img src={logo} alt="logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70px", width: "320px" }} />
            </div>
            <div className="nav">
              <Link to="/user"><button>Usuários</button></Link>
              <Link to="/desafios"><button>Desafios</button></Link>
              <Link to="/premios"><button className="ativado">Prêmios</button></Link>
              <Link to="/logs"><button >Logs</button></Link>
            </div>
        </div> 
      {/* Cabeçalho das Abas */}
      <div className="tab" style={{display : "flex", justifyContent: "space-around", alignItems: "center"}}>
        <button
          className={activeTab === "Cadastrar" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Cadastrar")}
        >
          Cadastrar
        </button>
        <button
          style={{width: "600px"}}
          className={activeTab === "Listar" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Listar")}
        >
          Listar
        </button>
        <button
          className={activeTab === "Compras" ? "tablinks active" : "tablinks"}
          onClick={() => handleTabClick("Compras")}
        >
          Compras
        </button>
      </div>

      {/* Conteúdo das Abas */}
      <div className="tab-content">
        {activeTab === "Cadastrar" && (
           <div className="tabcontent">
              <FormularioPremios/>
           </div> 
        )}
        {activeTab === "Listar" && (
           <div className="tabcontent">
              <PremiosList/>
          </div>
        )}
        {activeTab === "Compras" && (
          <div className="tabcontent">
            <ComprasList/>
          </div>
        )}
      </div>
    </div>
    );
  }
  
  export default Premios;
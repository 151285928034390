import React, { useState } from "react";
import { auth } from "./firebase"; // Importa a configuração do Firebase
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./App.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Configura a persistência da sessão para que a autenticação dure apenas durante a aba atual
      await setPersistence(auth, browserSessionPersistence);

      // Tenta fazer o login do usuário
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login bem-sucedido!");
      setSuccess("Login bem-sucedido!");
      navigate("/user");  // Redireciona para a página de usuário ou dashboard
      
    } catch (err) {
      // Em caso de erro, exibe mensagem
      setError("Usuário ou senha inválidos.");
    }
  };

  return (
    <div className="body" style={{ paddingTop: "80px" }}>
      <div className="container">
        <h4>Tela de Login do Administrador</h4>
        <form className="form" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email">E-mail:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button className="botao" type="submit">Login</button>
          {success && <p style={{ color: "green" }}>{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./logo.png";
import { Link } from "react-router-dom";
import { auth } from "./firebase"; // Importa a configuração do Firebase
import { useNavigate } from "react-router-dom";
import ModificacoesList from "./components/LogList";
import { onAuthStateChanged } from "firebase/auth";
function Logs() {
  const navigate = useNavigate();
    // Estado para armazenar a aba ativa (pode ser "Home", "Sobre", "Contato", etc.)
  const [activeTab, setActiveTab] = useState("Cadastrar");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Verifica a autenticação do usuário ao montar o componente
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Se o usuário está autenticado, atualiza o estado
        setIsAuthenticated(true);
      } else {
        // Se não estiver autenticado, redireciona para a página de erro
        navigate("/ErrorAuth");
      }
    });

    // Limpa o listener ao desmontar o componente
    return () => unsubscribe();
  }, [navigate]);

  // Função para alterar a aba ativa
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="body">
    <div className="cabecario">
        <div className="logo">
          <img src={logo} alt="logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70px", width: "320px" }} />
        </div>
        <div className="nav">
          <Link to="/user"><button>Usuários</button></Link>
          <Link to="/desafios"><button >Desafios</button></Link>
          <Link to="/premios"><button >Prêmios</button></Link>
          <Link to="/logs"><button className="ativado">Logs</button></Link>
          
        </div>
    </div> 
    <div style={{padding: "50px"}}>
      <ModificacoesList/>
    </div>
</div>
  );
}


export default Logs;